import { axios } from '@/utils/request.js'

const api = {
    sendCode (data) {  // 短信下发接口
        return axios.post('/api/sendcode', data)
    },

    qrcode (data) { // 获取客户二维码
        return axios.post('/index/kefu', data)
    },

    pageView (data) { // 跳转到页面数据
        return axios.get('/user/page', { params: data })
    },

    about () { // 咨询
        return axios.post('/index/about', {})
    },

    share(data) {
        return axios.get('/wxshare/getSignPackage', { params: data })
    }

    
}

export default api;