import { axios } from '@/utils/request.js'

const api = {
    index (data) {  // 首页
        return axios.get('/', { params: data })
    },

    detail(data) {  // 测试详细页
        return axios.post('/index/detail', data)
    },

    comment(data) {  // 评论列表
        return axios.post('/index/pinglun', data)
    },

    question(data) {  // 订单测评
        return axios.post('/index/ceping', data)
    },

    answer (data) { // 测评提交接口
        return axios.post('/index/addtestdata', data)
    },

    report (data) { // 测评分析接口
        return axios.post('/index/report', data)
    },

    getticketbysn (data) { // 报告获取二维码
        return axios.post('/index/getticketbysn', data)
    }

    
}

export default api;