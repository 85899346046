"use strict";
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)
const router = new VueRouter({
    // mode: 'hash',
    mode: 'history',
    routes: routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

router.beforeEach(async (to, from, next) => {
    // 微信url修改
    if (_global.wx) {
        let u = navigator.userAgent.toLowerCase()
        if (u.indexOf("like mac os x") < 0 || u.match(/MicroMessenger/i) != 'micromessenger' || u.match(/WebP/i) == "webp") {
            _global.route = from;
            document.title = to.meta.title
            next();
            return;
        }
        if (to.path != window.location.pathname) return location.assign(to.fullPath)
    }

    _global.route = from;
    document.title = to.meta.title
    next()
})

// router.afterEach((to, from) => {
//     // 微信url修改
//     let u = navigator.userAgent.toLowerCase()
//     if(u.indexOf("like mac os x") < 0 || u.match(/MicroMessenger/i) != 'micromessenger' || u.match(/WebP/i) == "webp") return
//     console.log(to.path != window.location.pathname, to.path, window.location.pathname)
//     if (to.path != window.location.pathname) {
//         // location.assign(to.fullPath)
//     }
// })

export default router;


