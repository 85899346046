<template lang="pug">
    .layout-page(
        :class="[bg, {'header': header}, {'tabbar': tabbar}]"
    )
        .layout-page-header(v-if="header")
            slot(name="header")
        .layout-page-content
            slot
        keep-alive
            router-view(v-if="$route.meta.keepAlive")
        router-view(v-if="!$route.meta.keepAlive")
</template>
<script>
export default {
    name: 'layout-page',
    props: {
        bg: {
            type: String,
            default: ''
        },
        header: {
            type: Boolean,
            default: false
        },
        tabbar: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style lang="less" scoped>
.layout                 {
    &-page              {min-height: 100%;
        &:before        {content: '';display: block;position: fixed;top: 0;left: 0;height: 100%;width: 100%;z-index: -1;background: #F5F7FA;}
        &.white:before  {background: #FFF;}
        &.header        {padding-top: 46px;}
        &.tabbar        {padding-bottom: 1.44rem;}
        &-header        {position: fixed;top: 0;left: 0;width: 100%;height: 46px;border-bottom: 1px solid #eaeaea;z-index: 100;background: #FFF;}
        &-content       {min-height: 100%;}
    }
}
</style>
