import { axios } from '@/utils/request.js'

const api = {
    login (data) {  // 注册登录接口
        return axios.post('/user/dologin', data)
    },

    bind(data) {  // 绑定手机号码
        return axios.post('/user/bindmobile', data)
    },

    logout () {
        return axios.post('/user/logout', {})
    },

    userInfo (data = {}) { // 获取用户详情
        return axios.post('/user', data)
    }
    
}

export default api;