<template lang="pug">
    .layout-page(:class="bg")
        slot
        keep-alive
            router-view(v-if="$route.meta.keepAlive")
        router-view(v-if="!$route.meta.keepAlive")
        
</template>
<script>
export default {
    name: 'layout-tab-page',
    props: {
        bg: {
            type: String,
            default: ''
        }
    }
}
</script>
<style lang="less" scoped>
.layout                 {
    &-page              {
        &:before        {content: '';display: block;position: fixed;top: 0;left: 0;height: 100%;width: 100%;z-index: -1;background: #F5F7FA;}
        &.white:before  {background: #FFF;}
    }
}
</style>
