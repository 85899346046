import cookie from './cookie.js'
export default {
    /**
     * 删除当前url中指定参数
     * @param names 数组或字符串
     * @returns {string}
     */
    delUrlParmas(names) {
        if(typeof(names)=='string') names = [names];
        let loca = window.location;
        let obj = {};
        let arr = loca.search.substr(1).split("&");
        let args = [];
        //获取参数转换为object
        for(let i = 0; i < arr.length; i++) {
            arr[i] = arr[i].split("=");
            obj[arr[i][0]] = arr[i][1];
        };
        //删除指定参数
        Object.keys(obj).map(key => {
			if (names.indexOf(key) >= 0) return obj[names[key]]
			args.push(key + '=' + obj[key])
        })
        
        var str = args.length > 0 ? ('?' + args.join("&")) : '';
		window.location.search = str
    },

    /**
     * 获取url的参数值
     * @param {string} name 参数值
     * @param {Array} args 是否清空url参数
     * @returns {string} 
     */
    getUrlParam(name, args = []) {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        let r = window.location.search.substr(1).match(reg);
        if (r == null) return 
        cookie.delCookie()
        cookie.setCookie('token', unescape(r[2]))
        if(args.length > 0) return this.delUrlParmas(args)
        return unescape(r[2]);
    },

    /**
     * 获取用户登录信息/用户信息
     * @param   {Function} c 回调函数
     * @returns {Object} 
     */
    getUserInfo (c) {
        if (_global.userInfo) return c && c({userInfo: _global.userInfo, loginInfo: _global.loginInfo})
        _global.callbackUserInfo = () => this.getUserInfo(c)
    },

    isWeixin () {
        let ua = window.navigator.userAgent.toLowerCase();
        return ua.match(/MicroMessenger/i) == 'micromessenger'
    },

    query (variable) {
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
       }
       return null
    }
}