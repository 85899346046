"use strict";

export default [
    {
        path: `/`,
        name: 'Ceping',
        meta: { title: '灵鸽心理', keepAlive: true, tabId: 0 },
        component: () => import(/* webpackChunkName: "ceping" */ '@/views/ceping/index.vue')
    },

    {
        path: `/find`,
        name: 'Find',
        meta: { title: '灵鸽心理', tabId: 1 },
        component: () => import(/* webpackChunkName: "find" */ '@/views/find/index.vue')
    },

    {
        path: `/about`,
        name: 'About',
        meta: { title: '咨询', keepAlive: true, tabId: 2 },
        component: () => import(/* webpackChunkName: "about" */ '@/views/about/index.vue')
    },

    {
        path: `/me`,
        name: 'Me',
        meta: { title: '我的', tabId: 3 },
        component: () => import(/* webpackChunkName: "me" */ '@/views/me/index.vue')
    },

    {
        path: `/answer`,
        name: 'Answer',
        meta: { title: ''},
        component: () => import(/* webpackChunkName: "answer" */ '@/views/ceping/answer/index.vue')
    },
    {
        path: `/detail`,
        name: 'Detail',
        meta: { title: '' },
        component: () => import(/* webpackChunkName: "detail" */ '@/views/ceping/detail/index.vue')
    },
    {
        path: `/report`,
        name: 'Report',
        meta: { title: '' },
        component: () => import(/* webpackChunkName: "report" */ '@/views/report/index.vue')
    },

    {
        path: `/login`,
        name: 'Login',
        meta: { title: '登录/注册' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/me/login/index.vue')
    },

    {
        path: `/bind`,
        name: 'Bind',
        meta: { title: '手机号码绑定' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/me/login/index.vue')
    },

    {
        path: `/view`,
        name: 'View',
        meta: { title: '' },
        component: () => import(/* webpackChunkName: "view" */ '@/views/page-view/index.vue')
    },

    {
        path: `/payment`,
        name: 'Payment',
        meta: { title: '' },
        component: () => import(/* webpackChunkName: "payment" */ '@/views/payment/index.vue')
    },

    {
        path: `/go`,
        name: 'Goview',
        meta: { title: '灵鸽心理' },
        component: () => import(/* webpackChunkName: "goview" */ '@/views/go-view/index.vue')
    },

    {
        path: '*',
        name: 'No-Found',
        meta: { title: '灵鸽心理', tabId: 0 },
        component: () => import(/* webpackChunkName: "nofound" */ '@/views/ceping/index.vue')
    }
];
