import { axios } from '@/utils/request.js'

const api = {
    add (data) {  // 注册登录接口
        return axios.post('/order/add', data)
    },

    show (data) {  // 查看订单接口
        return axios.post('/order/show', data)
    },

    ispay (data) {  // 查询订单是否支付
        return axios.post('/api/ispay', data)
    }

}

export default api;