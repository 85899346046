
const config = {
    development: {
        apiurl: '/~api',	// ~api：代理标识
        // proxy: 'http://test.2345pro.com',
        proxy: 'http://demoapi.taluoxz.cn ',
        wx_authorize: 'http://test.2345pro.com',
        // domain: 'http://localhost:7430',
        domain: 'http://xltest.2345pro.com',
        env: 'dev'
    },
    test: {                
        apiurl: 'http://test.2345pro.com',
        wx_authorize: 'http://test.2345pro.com',
        domain: 'http://xltest.2345pro.com',
        env: 'test'
    },
    demo: {                
        apiurl: 'http://demo.2345pro.com',
        wx_authorize: 'http://demo.2345pro.com',
        domain: 'http://xl.2345pro.com',
        env: 'demo'
    },
    demoapi: {                
        apiurl: 'http://demoapi.smdqw.net',
        wx_authorize: 'http://demoapi.smdqw.net',
        domain: '',
        env: 'demoapi'
    },
    xlapi: {                
        apiurl: 'http://xlapi.tlxz.net',
        wx_authorize: 'http://xlapi.tlxz.net',
        domain: '',
        env: 'xlapi'
    },
    production: {
        apiurl: 'http://xinliapi.2345pro.com',
        wx_authorize: 'http://xinliapi.2345pro.com',
        env: 'production'
    },

    smdqw: {
        apiurl: 'http://xinliapi.smdqw.net',
        wx_authorize: 'http://xinliapi.smdqw.net',
        domain: 'http://xinli.smdqw.net',
        env: 'smdqw'
    },

    xinliapi961916: {
        apiurl: 'http://xinliapi.961916.cn',
        wx_authorize: 'http://xinliapi.961916.cn',
        domain: 'http://xinli.smdqw.net',
        env: 'smdqw'
    },

    testxinliapi: {
        apiurl: 'http://testxinliapi.smdqw.net',
        wx_authorize: 'http://testxinliapi.smdqw.net',
        domain: 'http://xinli.smdqw.net',
        env: 'smdqw'
    },

    xinliapitkxingzuo: {
        apiurl: 'http://xinliapi.tkxingzuo.com',
        wx_authorize: 'http://xinliapi.tkxingzuo.com',
        domain: 'http://xl.tkxingzuo.com/',
        env: 'xinliapitkxingzuo'
    },

    taluoxz: {
        apiurl: 'http://demoapi.taluoxz.cn',
        wx_authorize: 'http://demoapi.taluoxz.cn',
        domain: 'http://xl.tkxingzuo.com/',
        env: 'taluoxz'
    },

    taotao008: {
        apiurl: 'http://demoapi.taotao008.com',
        wx_authorize: 'http://demoapi.taotao008.com',
        domain: 'http://xl.tkxingzuo.com/',
        env: 'taotao008'
    },

    taohua5: {
        apiurl: 'http://api.www.taohua5.cn',
        wx_authorize: 'http://api.www.taohua5.cn',
        domain: 'http://xl.tkxingzuo.com/',
        env: 'taohua5'
    }

    

    
}

module.exports = config[process.env.VUE_APP_CONFIG || 'development']