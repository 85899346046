import axios from 'axios'
import config from '@/../config'

axios.defaults.baseURL = config.apiurl;
axios.defaults.timeout = 10000;
axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'; //'application/x-www-form-urlencoded';  
axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'

// 添加请求拦截器
axios.interceptors.request.use((req) => {
    if (_global.token && req.method == 'post') req.data = { token: _global.token, uid: _global.uid, ...req.data }
    if (_global.token && req.method == 'get') req.params = { token: _global.token, uid: _global.uid, ...req.params }
    return req;
}, (error) => {
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use((response) => {
    // 微信环境下未登录则跳转授权
    if (_global.wx && response.data.status == 701 && process.env.NODE_ENV != 'development') return window.location.href = `${config.wx_authorize}/weixin/getopenid?backurl=${window.location.href}`
    
    // 对响应数据做点什么
    return response.data;
}, (error) => {
    // 判断请求异常信息中是否含有超时timeout字符串
    if (error.message.includes('timeout')) _vue.$notify('请求超时')
    // 对响应错误做点什么
    return Promise.reject(error);
});
const install = function(Vue, config = {}) {
    Vue.prototype.$request = axios
}

export {
    install,
    axios
};