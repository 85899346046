import 'lib-flexible'
import './assets/style/common.less'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/vant.js'
import './components/common/index.js'
import { cookie, tools } from '@/utils'
import * as axios from '@/utils/request'
import * as layout from '@/layout'
import { user } from '@/api'


window._vue = null
window._global = {
  isLogin: false,
  token: cookie.getCookie() || '',
  uid: cookie.getCookie('uid') || '',
  wx: tools.isWeixin(),
  lists: [],
  route: '',
  tools: tools,
  userInfo: {}
}

Vue.config.productionTip = false
Vue.use(axios)
Vue.use(layout)
// siteInit()

// 入口，获取登录状态
setTimeout(() => {
  // 判断url是否有 uid 和 token, 有的话替换 uid 和 token
  if (tools.query('uid') != null && tools.query('token') != null) {
    window._global.uid = tools.query('uid')
    window._global.token = tools.query('token')
    cookie.setCookie('uid', tools.query('uid'))
    cookie.setCookie('token', tools.query('token'))
  }

  // 获取用户信息并启动
  user.userInfo({
    token: window._global.token,
    uid: window._global.uid
  }).then(async (res) => {
    if (res.status != 1) return siteInit()
    _global.isLogin = true
    _global.userInfo = res.data.userinfo
    _global.lists = res.data.lists
    siteInit()
  }).catch(err => {
    console.log(err)
    cookie.delCookie()
    cookie.delCookie('uid')
    siteInit();
  })
}, 100)

// 全局初始化
function siteInit() {
  window._vue = new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
}
