<template lang="pug">
    .layout-tabbar
        .layout-tabbar-content
            a(:class="['item', {'active': $route.meta.tabId == i}]" v-for="(v, i) in tabbar" :key="i" @click="handleTapSwitch(v, i)")
                i.icon(:class="v.icon")
                span {{v.text}}
</template>
<script>
export default {
    name: "tabbar",

    methods: {
        /**
         * 导航切换函数
         * @param  {Object}  args
         * @param  {Number}  index
         */
        handleTapSwitch (args, index) {
            this.$router.push({path: args.path})
        }
    },

    data () {
        return {
            publicPath: process.env.BASE_URL,
            tabbar: [
                {text: '测评', path: '/', icon: 'icon-ceshi1'},
                {text: '查询', path: '/find', icon: 'icon-chaxun'},
                {text: '咨询', path: '/about', icon: 'icon-zixunzhuanyefuwu'},
                {text: '我的', path: '/me', icon: 'icon-wode'}
            ]
        }
    }
}
</script>
<style lang="less" scoped>
.layout-tabbar              {height: 1.466667rem;position: fixed;bottom: 0;left: 0;width: 100%;border-top: 1px solid #eaeaea;z-index: 100;background: #FFF;
    &-content               {max-width: 10rem;width: 100%;margin: 0 auto;display: flex;
        a                   {display: flex;flex: 1;flex-direction: column;justify-content: center;align-items: center;padding-top: .18666rem;
            i               {height: .7rem;.fs(26);color: #666;}
            span            {.fs(10);display: block;margin-top: .02rem;color: #666;}
            &.active        {
                span,i      {color: #5ec2b5;}
            }
        }
    }
}
</style>