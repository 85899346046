import { axios } from '@/utils/request.js'

const api = {
    index (data = {}) {  // 发现
        return axios.get('/order/lists', { params: data })
    },

    search (data = {}) {  // 搜索
        return axios.post('/order/search', data)
    }

    
}

export default api;