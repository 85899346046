import LayoutPage from './layout-page'
import LayoutTabPage from './layout-tab-page'

const version = '0.0.1'
const install = function(Vue, config = {}) {
    if (install.installed) return
    Vue.component(LayoutPage.name, LayoutPage)
    Vue.component(LayoutTabPage.name, LayoutTabPage)
}

// auto install
if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue);
};

export {
    install,
    version,
    LayoutPage,
    LayoutTabPage
}